// WRITE ARTICLE
import { DocumentTextIcon } from "@heroicons/vue/20/solid";

export const addNotesToDatapoints = {
  order: 0,
  name: "Add notes to datapoints",
  subcategory: "Notes & Files",
  icon: DocumentTextIcon,
  iconColor: "text-slate-700",
  markdown: `# Add notes to datapoints`,
};
